import '../../styles/Global.css'


const RadioButton = ({data , onChange , userData}) =>{

    return(
        <div className="radio-button">
        {
           data.map((item)=>(
            <div className={`${userData.gender === item.value ? 'checked' : 'not-checked'}`}>
                <input type="radio" id={item.value} checked={userData.gender === item.value} value={item.value} name={item.name} onChange={onChange} />
                <label htmlFor={item.value}>{item.value}</label>
            </div>
           ))
        }
        </div>
    )
}

export default RadioButton