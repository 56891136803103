import { FaRegUser} from "react-icons/fa";
import { HiOutlineChat } from "react-icons/hi";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../context/User-Context";
import { db } from "../Firebase";
import { HiMiniUserGroup } from "react-icons/hi2";
import { RiVerifiedBadgeLine } from "react-icons/ri";


import { doc , onSnapshot } from "firebase/firestore";
import '../styles/Feed.css'


const DashboardWrapper = ({title , children , fade = false}) => {
  const [unreadLoading , setUnreadLoading ] = useState(true)
  const [unread , setUnread] = useState(null)
  const {currentUser} = useUser()


  const [icons, setIcons] = useState([
    { name: 'home', icon: <HiMiniUserGroup/>, isActive: false, href : '/feed' },
    { name: 'premium', icon: <RiVerifiedBadgeLine/>, isActive: false, href : '/premium' },
    { name: 'chat', icon: <HiOutlineChat />, isActive: false , href : '/chat' },
    { name: 'profile', icon: <FaRegUser />, isActive: false , href : '/profiledetails' },
  ]);
 

useEffect(() => {
  setIcons((icons) =>
    icons.map((item) =>
      item.name === title ? { ...item, isActive: true } : { ...item, isActive: false }
    )
  );
}, [title]);


useEffect(() => {
  if (!currentUser?.uid) return;

  const ref = doc(db, 'userchats', currentUser.uid);

  const unsubscribe = onSnapshot(ref, (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();
      const unreadUserChats = data?.chats?.filter((chat) => !chat.isSeen && chat.isShown);
      setUnread(unreadUserChats?.length);
    }
    setUnreadLoading(false)
  });

  return () => unsubscribe();

}, [currentUser]);

if(unreadLoading){
  return;
}


  return (
    <div className="top-wrapper-container">
        <div className="wrapper-container">
          {children}
          <div className="top-homepagefooter-container">
            {fade && <div className="fade"></div>}
                <div className="homepagefooter-container">
                  {icons.map((icon, index) => (
                    <Link to={icon.href} key={index}>
                    <div 
                      key={index} 
                      className={`${icon.isActive ? "isactive-homepagefooter-icon" : "homepagefooter-icon"}`} 
                    >
                      {icon.icon}
                      {(icon.name === 'chat' && unread > 0) && <div className="unread"><span>{unread}</span></div>}
                    </div>
                  </Link>
                ))}
              </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;