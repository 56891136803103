export const ValidateAge = (date) => {
    if (date.trim() === '') {
        return { valid: false, message: 'Date of birth is required!' };
    }

    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    if (age < 18) {
        return { valid: false, message: 'Age must be at least 18 years old!' };
    }

    return { valid: true, message: ''}; 

};
