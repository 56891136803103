import AuthError from "../../../components/auth/AuthError"
import InputText from "../../../components/auth/InputText"
import Label from "../../../components/auth/Label"


const Name = ({ userData , HandleChangeForm , error }) =>{

    return(
        <>
        <div className='name-signup-container'>
            <div>
                <Label>Name</Label>
            </div>
            <div>
                <InputText value={userData.name || null} type='text' placeholder='Input your name' name='name' onChange={(e) => HandleChangeForm(e)}/>
            </div>
            <div>
                <AuthError>{error}</AuthError>
            </div>
        </div>
        
        </>
    )
}

export default Name