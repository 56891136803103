import DashboardWrapper from "../../components/DashboardWrapper";
import '../../styles/Editphotos.css';
import { useUser } from "../../context/User-Context";
import useFetchProfile from "../../hooks/use-fetch-profile";
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { useEffect, useState } from "react";
import { db, storage } from "../../Firebase";
import { ref, uploadBytes , getDownloadURL, deleteObject } from "firebase/storage";
import { updateDoc , doc} from "firebase/firestore";
import { toast } from "react-toastify";
import { LoadingCircle } from "../../components/LoadingCircle";
import { HeaderType2 } from "../../components/HeaderType2";

const EditPhoto = () => {
    const { currentUser } = useUser();
    const { profile, profileLoading } = useFetchProfile(currentUser?.uid);
    const [newPhotos, setNewPhotos] = useState([]);
    const [uploadPhotoloading , setUploadPhotoLoading] = useState(false)
    const [isMenuOpen , setIsMenuOpen] = useState(false)




    const fill = () =>{
        //on fetch user from database fill them so we can map them
        if (profile?.userImages) {
            const remainingSpace = 5 - profile.userImages.length;
            setNewPhotos(profile.userImages.concat(Array(Math.max(remainingSpace, 0)).fill('')));
        }
    }

    useEffect(() => {
        fill() 
    }, [profileLoading, profile]);

    // console.log(profile);

    if (profileLoading) {
        return <LineLoadingScreen />;
    }

    const handlePhotoSelection = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const updatedPhotos = [...newPhotos];
            const newURL = URL.createObjectURL(file);
            updatedPhotos[index] = newURL;
            setNewPhotos(updatedPhotos);
    
            // Revoke the previous object URL (if any) when a new file is selected
            return () => URL.revokeObjectURL(newURL);
        }
    };

    const handleSave = async () => {
        setUploadPhotoLoading(true)
        
        
        //remove falsy values
        const cleanedData = newPhotos.filter(photo => photo);

        //check if no changes were made and return early
        // console.log(cleanedData , profile.userImages,'good')

        if(JSON.stringify(cleanedData) === JSON.stringify(profile?.userImages)){
            // console.log(cleanedData , profile.userImages)
            toast.info('No changes made')
            setUploadPhotoLoading(false)
            return
        }

            const uploadTasks = cleanedData.map(async (photo, index) => {
                // console.log(photo,'good')

            if (photo.startsWith('blob:')) {
                try {
                    const file = await fetch(photo).then(res => res.blob());
                    const imageRef = ref(storage, `users/${profile.id}/photo_${index}`);
                    await uploadBytes(imageRef, file);
                    return await getDownloadURL(imageRef);
                } catch (err) {
                    // console.error('Upload failed for photo:', err);
                    return null;
                }
            } else {
                // for already firebase pictures url we want to delete
                profile.userImages.forEach(async(item) => {
                    // console.log('good')
                    if (!cleanedData.includes(item)) {
                        const decodedUrl = decodeURIComponent(item);
                        const parts = decodedUrl.split('/');
                        const photoName = parts[parts.length - 1].split('?')[0];
                        const imageRef = ref(storage, `users/${profile.id}/${photoName}`);
                        // console.log(imageRef)
                        try {
                            await deleteObject(imageRef);
                        } catch (err) {
                            // console.log(err);
                        }
                    }
                });
                return photo;
            }
        });
    
        const arrayUpload = (await Promise.all(uploadTasks)).filter(url => url);
    
        const userRef = doc(db, 'users', profile?.id);
        try {
            await updateDoc(userRef, {
                userImages: arrayUpload,
            });
            toast.success('Pictures saved');
            setUploadPhotoLoading(false)
        } catch (err) {
            setUploadPhotoLoading(false)
            // console.error('Error updating Firestore:', err);
        }
    };

    const handleDeletePhoto = (index) =>{
        const updatedPhotos = [...newPhotos];
        updatedPhotos[index] = ''
        setNewPhotos(updatedPhotos)

    }

    const Reset  = () =>{
        fill()
        setIsMenuOpen(false)
    }

    const options = [{name : 'Reset' , action : Reset}]

    

    // console.log(newPhotos)

    // console.log(profile);

    return (
        <DashboardWrapper>
            <div className="edit-photo-header">
                <HeaderType2 title='Edit Photos' options={options} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            </div>
            <div className="photo-container comic-font">
                <div className="top-edit-photo-container">
                    {newPhotos.length > 0 && newPhotos.slice(0, 3).map((photo, index) => (
                        <div className="edit-photo-container" key={`photo-${index}`}>
                            <div>
                                <img src={photo || '../svgs/avatar.svg'} alt="user" />
                            </div>
                            <input type="file" accept="image/*" id={`file-${index}`} onChange={(e) => handlePhotoSelection(e, index)} />
                            <div>
                                <div>
                                    <label htmlFor={`file-${index}`}>+</label>
                                </div>
                                {(index !== 0 && photo) &&
                                <div onClick={()=>handleDeletePhoto(index)}>
                                    <img src="../svgs/bin.svg"  alt="bin"/>
                                </div>
                                }
                            </div>
                            <div>
                                {index === 0 && <span>Profile Picture</span>}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="top-edit-photo-container-2">
                {newPhotos.length > 3 && 
                newPhotos.slice(3).map((photo, i) => {
                    const index = i + 3; // correct index to match `newPhotos`
                 return (
                    <div className="edit-photo-container-2" key={`photo-${index}`}>
                        <div>
                            <img src={photo || '../svgs/avatar.svg'} alt="user" />
                        </div>
                        <input 
                            type="file" 
                            id={`file-${index}`} 
                            accept="image/*"
                            onChange={(e) => handlePhotoSelection(e, index)} 
                        />
                        <div>
                            <div>
                                <label htmlFor={`file-${index}`}>+</label>
                            </div>
                            {photo && (
                                <div onClick={() => handleDeletePhoto(index)}>
                                    <img src="../svgs/bin.svg" alt="bin"/>
                                </div>
                            )}
                        </div>
                    </div>
                            );
                        })
                    }
                    </div>


                <div className="edit-photo comic-font">
                    <button onClick={handleSave} disabled={uploadPhotoloading}>{ uploadPhotoloading ? <LoadingCircle/> : 'Save'}</button>
                </div>
            </div>
        </DashboardWrapper>
    );
};

export default EditPhoto;
