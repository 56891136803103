import { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import '../../styles/Global.css'

const Body = () => {
    
    
    const reviewArray = [{name : 'Alex' , review : "Before discovering VidMeet, I was hesitant and shy about creating fashion content. Despite having a passion for fashion and plenty of ideas the thought of shooting content felt overwhelming. That all changed when I found the perfect partner through this platform"} ,
        {name : 'John' , review : "Joining VidMeet transformed my streaming journey! I connected with an awesome co-streamer, and our live streams exploded. Together, we gained thousands of followers and reached new milestones faster than I ever expected. VidMeet made finding the perfect partner so easy!"},
        {name : 'Alisha' , review :"Before joining VidMeet, launching a podcast felt like a solo uphill climb. Then I connected with the perfect co-host, and everything changed. We quickly hit a million views, built a loyal following, and finally made our dream podcast a reality. VidMeet made it easy to find the right partner!"}]

    const [name, setName] = useState(reviewArray[0].name);
    const [review , setReview] = useState(reviewArray[0].review);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % reviewArray.length;
                setName(reviewArray[newIndex].name)
                setReview(reviewArray[newIndex].review)
                return newIndex; 
            });
        }, 7000);

        return () => clearInterval(interval);
    }, []);

   


    return ( 
    <>  
        <div className="body-1 poppins-font">
            <div className="main1">
               <img src="../svgs/hero-square.svg" alt="square"/>
               <div>
                    <img src="../svgs/dynamite.svg" alt="dynamite"/>


                    <div>
                        <img src="../svgs/hero-element1.svg" alt="hero-line"/>
                        <span>Connect, collaborate and thrive</span>
                    </div>


                    <div>
                        <span>Collaborate and </span>
                        <span>create something </span>
                        <span>extraodinary</span>
                    </div>

                    <div>
                        <span>Join VidMeet to connect with like-minded content creators build </span>
                        <span>confidence and start sharing content today!</span>
                    </div>
                    <div>
                        <Link to='/signup'>Get Started</Link>
                    </div>
               </div>
               <div>
                    <img src="../svgs/dynamite.svg" alt="dynamite"/>
                    <img src="../images/bg.png" alt="bg-pic" />
               </div>

            </div>
        </div>
        <div className="body-2 poppins-font">
            <div className="main-2">
                 <div>
                    <img src="../svgs/dynamite.svg" alt="dynamite"/>
                    <span>For the creators</span>
                 </div>
                 <div>
                    <div>
                        <div>
                            <span>10K+</span>
                            <span>Active Creators</span>
                        </div>
                        <div>
                            <span>
                            VidMeet is alive with activity—content creators are sharing fresh ideas, collaborating on exciting projects, and inspiring others with their creativity.                             
                             </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>20+</span>
                            <span>Content Preferences</span>
                        </div>
                        <div>
                            <span>
                            At VidMeet, we celebrate your unique content preferences by offering a diverse range of genres and styles. Whether you're into Fashion, Game streaming, or Gym/Fitness, our platform provides personalized recommendations, connecting you with creators who inspire and resonate with you.                            </span>
                        </div>
                    </div>
                    <div>
                        <img src="../svgs/flower.svg" alt="flower"/>
                        <div>
                            <span>5K+</span>
                            <span>Collaborations</span>
                        </div>
                        <div>
                            <span>
                            At VidMeet, collaboration is at the heart of what we do. We connect like-minded creators, making it easy to share ideas and create together.                          
                            </span>
                        </div>
                    </div>
                 </div>

            </div>

        </div>
        <div className="body-3 poppins-font">
            <div className="main-3">
                <div>
                    <img src="../svgs/dynamite.svg" alt="dynamite"/>
                    <span>Why choose VidMeet</span>
                </div>
                <div>
                    <span>The VidMeet <span>Advantage</span></span>
                </div>
                <div>
                    <span>Unlock seamless connections, personalized content, and easy collaboration</span>
                </div>
                <div>
                    <div>
                        <div>
                            <div>
                                <span>Why choose VidMeet</span>
                            </div>
                            <div>
                                <span>Unlock seamless connections, personalized content, and easy collaboration</span>
                            </div>
                            <div>
                                <Link to='/signup'>Get started</Link>
                            </div>
                        </div>
                        <div>
                        <img src="../svgs/diverse-logo.svg" alt="i"/>
                        <span>
                                    Diverse Community
                                </span>
                                <span>
                                VidMeet fosters a diverse community, bringing together people from all backgrounds to share and create unique content.
                                </span>                        
                        </div>
                    </div>
                    <div>
                    <div>
                            <img src="../svgs/tailored-networking.svg" alt="i"/>
                            <img src="../svgs/logo-bg.svg" alt="logo"/>

                            <span>
                                Tailored Networking
                            </span>
                            <span>
                            VidMeet offers tailored networking, connecting you with like-minded creators to foster meaningful collaborations.
                            </span>
                        </div>
                        <div>
                        <img src="../svgs/messaging-logo.svg" alt="i"/>
                                <span>
                                Effortless Messaging
                                </span>
                                <span>
                                VidMeet makes messaging effortless, allowing seamless communication for easy connections and collaboration.
                                </span>                        
                        </div>
                    </div>
                    <div>
                        <div>
                        <img src="../svgs/global-reach-logo.svg" alt="i"/>
                                <span>
                                    Global Reach
                                </span>
                                <span>
                                With a global reach, VidMeet connects people worldwide, bringing diverse ideas and content together.                                </span>                         
                            </div>
                        <div>
                        <img src="../svgs/messaging-logo.svg" alt="i"/>
                        <span>
                                    Effortless Messaging
                                </span>
                                <span>
                                VidMeet makes messaging effortless, allowing seamless communication for easy connections and collaboration.
                                </span>                         
                            </div>
                    </div>
                </div>
            </div>

        </div>
        <div className="body-4 poppins-font">
            <div className="main-4">
                <div>
                    <div>
                        <img src="../svgs/icon-1.svg" alt="icon"/>
                        <span>About VidMeet</span>
                    </div>
                    <div>
                        <span>Where <span>Content</span> and Collaboration Converge</span>
                    </div>
                    <div>
                        <span>
                            Be part of VidMeet - your hub for connecting
                            with creators who share your interests, whether it's Fashion,
                            Gym/Fitness, Game streaming, Lifestyle vlogging, or starting a
                            Podcast and looking for hosts, VidMeet makes it
                            easy to find your community.
                        </span>
                    </div>
                    <div>
                        <Link to='/signup' >Start collaborating</Link>
                    </div>
                </div>
                <div>
                    <img src="../svgs/people.svg" alt="people"/>
                </div>
            </div>
        </div>
        <div className="body-5 poppins-font">
            <div className="main-5">
                <div>
                    <img src='../svgs/dynamite.svg' alt='dynamite'/>
                    <span>Connecting creators</span>
                </div>
                <div>
                    <span>The <span>biggest networking</span> app for content creators</span>
                </div>
                <div>
                    <span>The Leading Networking Platform Designed Exclusivelty for Content Creators</span>
                </div>
                <div>
                    <img src="../svgs/map.svg" alt="map"/>
                </div>
            </div>
        </div>
        <div className="body-6 poppins-font">
            <div className="main-6">
                    <img src="../svgs/hero-square.svg" alt="square"/>
                    <img src="../svgs/hero-square.svg" alt="square"/>

                <div>
                    <span>Frequently asked Questions</span>
                </div>
                <div>
                    <span>
                        For any other questions or support, feel free to reach out to our team. 
                        We're here to help you connect, collaborate, and grow with ease!
                    </span>
                </div>
                <div>
                    <span>General</span>
                </div>
                <div>
                    <FAQ question={'How do i get started?'} answer={'Answer a few simple questions, choose two content areas you’re passionate about, connect with like-minded creators, and start collaborating!'}/>
                    <FAQ question={'Is there a free version of the app?'} answer={'Yes, our Minimum Viable Product (MVP) offers a free tier with essential functionality for connecting with other content creators. You can start networking without any charges.'}/>
                    <FAQ question={'Is my data safe with VidMeet?'} answer={'Yes, your data is safe with VidMeet. All personal information is stored securely. Our privacy policy ensures that your data is only used to improve your experience on the platform.'}/>
                    <FAQ question={'Is this app specifically designed for content creators?'} answer={'Yes! VidMeet makes it effortless for content creators to connect, collaborate, and grow together.'}/>
                    <FAQ question={'I am just starting to make content can i join VidMeet?'} answer={"Absolutely! If you're just starting out as a content creator, VidMeet is the perfect place to connect, collaborate, and grow alongside like-minded individuals."}/>
                    <FAQ question={"I am anxious to create and post content can i still join VidMeet?"} answer={'Absolutely! VidMeet helps anxious creators connect with like-minded individuals, making it easy to collaborate and create unique content together.'}/>

                </div>
            </div>
        </div>
        {/* <div className="body-7">
            <div className="main-7">
                    <div>
                        <img src='../svgs/dynamite.svg' alt='dynamite'/>
                        <span>Testimonials</span>
                    </div>
                    <div>
                        <span>See what our <span>Users </span>are saying</span>
                    </div>
                   
            </div>
        </div> */}

    </>
     );
}
 
export default Body;



export const FAQ = ({question , answer}) =>{

    const [answerShow , setAnswerShow] = useState(false)

    return(
        <div className="each-faq">
            <div>
                <span>{question}</span>
                <img onClick={()=>setAnswerShow(!answerShow)} src={!answerShow ? "../svgs/faq-show-logo.svg" : "../svgs/faq-hide-logo.svg" } alt="logo"/>
            </div>
            { answerShow && 
                <div>
                  <span>{answer}</span>
              </div>
            }
          
        </div>
    )
}