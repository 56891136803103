import '../../styles/ForgottenPassword.css'
import { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../../Firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import LeftBg from "../../components/auth/LeftBg";
import Label from "../../components/auth/Label";
import InputText from "../../components/auth/InputText";
import AuthButton from "../../components/auth/AuthButton";
import AuthError from "../../components/auth/AuthError";
import '../../styles/Global.css'


const ForgottenPassword = () =>{

    const [email ,setEmail] = useState('')
    const [emailError , setEmailError] = useState('')
    const [emailLoading , setEmailLoading] = useState(false)
    const [emailSent , setEmailSent] = useState(false)
    const navigate = useNavigate()

    

    const HandleEmail = async (e) => {
        setEmailError('')
        e.preventDefault();
    
        if (!email) {
            setEmailError("Email is required");
            return;
        }
    
        if (!email.includes("@") || !email.includes(".")) {
            setEmailError("Email is invalid");
            return;
        }
    
        const ref = collection(db, "users");
        const q = query(ref, where("email", "==", email));
    
        try {
            setEmailLoading(true);
    
            const querySnapshot = await getDocs(q);
            // console.log("Query result:", querySnapshot.empty);
    
            if (querySnapshot.empty) {
                setEmailError("Email not recognized");
                return;
            }
            await sendPasswordResetEmail(auth, email);
            setEmailSent(true)
            //users should be done reading the email message redirect to login page auto after 10seconds
            setTimeout(()=>{
                navigate('/login')
            },10000)
        } catch (error) {
            if (error.code === "auth/invalid-email") {
                setEmailError("Invalid email format. Please check and try again.");
            } else if (error.code === "auth/network-request-failed") {
                setEmailError("Network error. Please try again later.");
            } else {
                setEmailError("An unexpected error occurred. Please try again.");
            }
        } finally {
            setEmailLoading(false);
    
        }
    };
    

    return(
        <div className="password-forgotten poppins-font">
            <div>
                <LeftBg/>
            </div>
            <div>
                <div>
                    { !emailSent ?
                    <>
                    <div>
                        <div><span>Forgotten Password</span></div>
                    </div>
                    <div>
                        <div><span>Enter your registered email address</span></div>
                    </div>
                    <div>
                        <Label>Email Address</Label>
                        <InputText type='email' onChange={(e)=>setEmail(e.target.value)} value={email} />
                    </div>
                    <div>
                        <AuthError>{emailError}</AuthError>
                    </div>
                    <div>
                        <AuthButton Handle={HandleEmail} loading={emailLoading} text='Proceed'></AuthButton>
                    </div>
                    </> : 
                     <div className="password-sent">
                        <div>
                            <img src="../svgs/inbox.svg" alt="inbox"/>
                        </div>
                        <div>
                            <span>Password reset link</span>
                        </div>
                        <div>
                            <span>We've sent a password reset link to your email! Check your inbox and click the link to set a new password.</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        // <>
        // <div className='password-forgotten-body'>
            
        //     <div className='password-forgotten-form'>
        //         {!emailSent ? 
        //         <>
        //         <div>
        //             <span>Forgotten Password</span>
        //         </div>
        //         <form>
        //             <div>
        //                 <input type='text' placeholder='Your Email' value={email} onChange={(e)=> setEmail(e.target.value)} />
        //             </div>
        //             <div>
        //                 <button disabled={emailLoading} onClick={(e)=>HandleEmail(e)}>{!emailLoading ? 'Next' : <LoadingCircle/>}</button>
        //             </div>
        //             <p className='error'>{emailError}</p>
        //         </form>
        //         </>
        //         : <div className="password-sent">
        //             <img src="../svgs/inbox.svg" alt="inbox"/>
        //             <p>We've sent a password reset link to your email! Check your inbox 
        //                 and click the link to set a new password.</p>
        //         </div>
        //         }
        //     </div>    
        // </div>
        // </>
    )
}

export default ForgottenPassword
