import '../../styles/Global.css'


const InputText = ({placeholder, value, onChange , name , type}) =>{

    return(

        <input
        className="input-text"
        placeholder={placeholder}
        type={type}
        value={value}   
        onChange={onChange}   
        name = {name}
      />
    )
}

export default InputText;