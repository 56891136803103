import { useState } from "react"
import { db } from '../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import getAge from "../functions/GetAge";

export const useFilter = () =>{
    const [filterLoading , setFilterLoading] = useState(false)


    const handleFilter = async (filteredValues,profile,setFeed,setFilter, setFilterActive) =>{
        // console.log(filteredValues , profile)
          
        try {
            setFilterLoading(true)
          
            const ref = collection(db, 'users');
            const q = query(
              ref,
              where('userId', '!=', profile?.id),
              where('country', '==', profile?.country)
            );
          
            let result = await getDocs(q);
            result = result.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          
            // Apply all filters together
            const filteredResult = result.filter(item => {
              // Age filtering
              const age = getAge(item.dateOfBirth);
              const ageMatch =
                (!filteredValues.minAge || age >= filteredValues.minAge) &&
                (!filteredValues.maxAge || age <= filteredValues.maxAge);
          
              // Content filtering (skip if empty)
              const contentMatch = filteredValues.content
                ? Array.isArray(item.content) && item.content.includes(filteredValues.content)
                : true;
          
              // Gender filtering (skip if empty)
              const genderMatch = filteredValues.gender
                ? item.gender === filteredValues.gender
                : true;
          
              // Region filtering (skip if empty)
              const regionMatch = filteredValues.region
                ? item.region === filteredValues.region
                : true;
          
              // Combine all conditions
              return ageMatch && contentMatch && genderMatch && regionMatch;
            });

            if(filteredResult.length === result.length ){
                setFilterActive(false)
            }else{
                setFilterActive(true)
            }

          
            setFeed(filteredResult);
            setFilter(false);
            setFilterLoading(false);
          } catch (error) {
            // console.error("Error fetching or filtering data: ", error);
            setFilterLoading(false);
          }
          
          
          
          
        
    }

    return {handleFilter , filterLoading}
}