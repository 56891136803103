import AuthError from "../../../components/auth/AuthError";
import InputSelect from "../../../components/auth/InputSelect";
import Label from "../../../components/auth/Label";
import TextArea from "../../../components/auth/TextArea";

const EthnicityAbout = ({userData , HandleChangeForm , error}) =>{

    const Ethnicities = ['Asian', 'Black', 'Caucasian (white)', 'Hispanic', 'Middle Eastern', 'Mixed Race', 'South Asian'];

    return(
        <div className="ethnicity-signup-container">
            <div>
                <Label>Ethnicity</Label>
                <InputSelect name="ethnicity" data={Ethnicities} customPlaceholder='Select your ethnicity' value={userData.ethnicity || null} onChange={HandleChangeForm} />
            </div>
            <div>
                <Label>Write a little about yourself(Bio)</Label>
                <TextArea name="description" onChange={HandleChangeForm} value={userData.description ||null} placeholder="e.g I love making fashion content and would really like to find some fashion friends to create and post fashion content together!" ></TextArea>
            </div>
            <div>
                <AuthError>{error}</AuthError>
            </div>
     
    </div>
    )
}

export default EthnicityAbout