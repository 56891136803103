import '../../styles/Global.css'
import { useState , useRef } from 'react';




const InputPassword = ({placeholder, value, onChange}) =>{

    const [hashed , setHashed] = useState(false)

    const inputRef = useRef(null)

    

    const hashPassword = (e) =>{
        !hashed ? inputRef.current.type = 'text' : inputRef.current.type = 'password'
        setHashed(!hashed)
      }
    
    return(
        <div className='input-password'>
            <input className='input-text' placeholder={placeholder} type="password" ref={inputRef} value={value} onChange={onChange} />
            <img onClick={hashPassword} src={hashed ? '../svgs/password-hide.svg' : '../svgs/password-show.svg'} alt='i' />
        </div>
    )
}

export default InputPassword