import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastNotification = () => {


    return(
        <div>
        <ToastContainer
        position="top-center"
        autoClose={1000}
        newestOnTop={false}
        hideProgressBar={true}
        theme="dark"
        limit={1}
        />
      </div>
    )
 
};

// Export both the ToastContainer and toast functions
export { toast };
export default ToastNotification;
