import '../../styles/SignUp.css'
import useEmailPasswordSignup from '../../hooks/use-email-password-signup'
import { LoadingCircle } from '../../components/LoadingCircle'
import AuthButton from '../../components/auth/AuthButton'
import '../../styles/Global.css'

const EmailVerification = ({userData,userCreated,setSteps}) =>{
 const { signupWithEmailPassword, secondSignupLoading } = useEmailPasswordSignup()



    return(
          <div className="email-verification-container poppins-font">
            <div>
                <img src="'../../svgs/inbox.svg" alt="inbox"/>
            </div>
            <div>
                <p>Account verification link sent!</p>
            </div>
            <div>
                <p>Please check your email <span>{userData.email}</span> to verify your account, then click the button below to continue without refreshing the page.</p>
            </div>
            <div>
                <AuthButton text={secondSignupLoading ?  <LoadingCircle/> : 'I have verified my email'} Handle={()=>signupWithEmailPassword(userData,userCreated)} loading={secondSignupLoading} />
            </div>
            <div>
              <span onClick={()=>setSteps(6)}>Change email</span>
            </div>
        </div>
    )
}

export default EmailVerification