import '../../styles/Global.css'
import { LoadingCircle } from '../LoadingCircle'


const AuthButton = ({text , Handle , loading}) =>{
    return(
        <button disabled={loading} onClick={Handle} className="auth-button">{!loading ? text : <LoadingCircle/>}</button>
    )
}

export default AuthButton