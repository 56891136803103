import '../../styles/LandingPage.css'
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

function Home() {


  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default Home;
