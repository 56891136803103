import '../../styles/Global.css'


 const LeftBg = () =>{
    return (
        <div className="left-bg" > 
            <p></p>
        </div>
    )
}

export default LeftBg