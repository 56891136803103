export const ValidateDescription = (description) => {
    if(description.trim() === ''){
        return {valid : false , message : 'Please tell us about yourself'}
    }
    if(description.length < 30){
        return {valid : false , message : 'Bio must be more than 30 characters' }
    }

    return {valid : true ,  message : ''}

}