import AuthError from "../../../components/auth/AuthError"
import Label from "../../../components/auth/Label"
import RadioButton from "../../../components/auth/RadioButton"
import DateInput from "../../../components/auth/DateInput"

const GenderAge = ({userData , HandleChangeForm, error }) =>{

    const maxDate = new Date().toISOString().split('T')[0]

    const data = [
        {value : 'Male' , name : 'gender' },
        {value : 'Female' , name : 'gender' },
        {value : 'Non-binary' , name : 'gender' },
    ]

    return(
        <>
        <div className="gender-age-container">
        <div>
            <Label>Select gender</Label>
            <RadioButton data={data} onChange={HandleChangeForm} userData={userData} />
        </div>
        <div>
            <Label>Date of birth</Label>
            <DateInput max={maxDate} name='dateOfBirth' value={userData.dateOfBirth || null} onChange={HandleChangeForm} />
        </div>
        <div>
            <AuthError>{error}</AuthError>
        </div>


        </div>
        
        </>
    )
}

export default GenderAge