import React, { useRef, useEffect, useState } from "react";
import useFetchFeed from "../../hooks/use-fetch-feed";
import { BiSolidCamera } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import "../../styles/Feed.css";
import DashboardWrapper from "../../components/DashboardWrapper";
import getAge from "../../functions/GetAge";
import { Link } from "react-router-dom";
import { EmptyData } from "../../components/EmptyData";
import LineLoadingScreen from "../../components/LineLoadingScreen";
import { FormatContent } from "../../functions/FormatContent";
import Filter from "../../components/Filter";
import { useUser } from "../../context/User-Context";
import useFetchProfile from "../../hooks/use-fetch-profile";
import '../../styles/Global.css'


const Feed = () => {
  const {currentUser} = useUser()
  const { profile } = useFetchProfile(currentUser?.uid)
  const { feed, feedLoading , setFeed } = useFetchFeed();
  const feedContainerRef = useRef(null);
  const [filter , setFilter] = useState(false)
  const [filterActive , setFilterActive] = useState(false)

  useEffect(() => {
    if (feed && !feedLoading && feedContainerRef.current) {
      const lastScrollPosition = Number(localStorage.getItem("scrollPosition"));
      if (lastScrollPosition) {
        feedContainerRef.current.scrollTop = lastScrollPosition;
      }
    }
  }, [feed, feedLoading]);
  // console.log(feed)

  const handleScroll = (e) => {
    const currentScroll = e.target.scrollTop;
    localStorage.setItem("scrollPosition", currentScroll);
  };

  if (feedLoading || !feed || !profile) {
    return <LineLoadingScreen />;
  }

  return (
    <DashboardWrapper title="home" fade={true}>
      <Filter setFeed={setFeed} setFilter={setFilter} filter={filter} profile={profile} setFilterActive={setFilterActive}/>
      <div className="top-homefeed-container comic-font">
        <div
          className="homefeed-container"
          onScroll={handleScroll}
          ref={feedContainerRef}
        >
          {feed.length > 0 ? (
            <>
              {feed.map((feedItem) => (
                <div className="feed-container" key={feedItem.id}>
                  <img src={feedItem.userImages[0]} alt="user" />
                  <div>
                    <div>
                      <Link to={`/eachuser/${feedItem.id}`}>
                        <span>{feedItem.name}</span>,<span>{getAge(feedItem.dateOfBirth)}</span>{feedItem?.isPremium && <img src="../svgs/verifiedicon.svg"  alt="verified"/>}
                      </Link>
                    </div>
                    <div>
                      <span>
                        <BiSolidCamera />
                      </span>
                      <span>
                        {FormatContent(feedItem.content[0],feedItem.content[1])}
                      </span>
                    </div>
                    <div>
                      <span>
                        <MdLocationOn />
                      </span>
                      <span>
                        {feedItem.region}, {feedItem.country}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="end-scroll">
                <p>End of scroll...</p>
              </div>
            </>
          ) : (
            <EmptyData title={<>Your Feed is empty.<br />Try Re-adjusting your filter!😔</>} />
          )}
        </div>
        <div className="filter" onClick={()=>{
          setFilter(true)
        }}>
          {filterActive && <div>1</div>}
          <LuSettings2 />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Feed;
