import '../../styles/LandingPage.css'
import {Link} from 'react-router-dom'
import '../../styles/Global.css'



import { useState } from 'react';


const Header = () => {
    const [responsive , setResponsive] = useState(false)

    window.onresize = function() {
        const windowWidth = window.innerWidth;
        if(windowWidth > 790){
            setResponsive(false)
        }
    };
   

    const HandleMenu = () =>{
        setResponsive(!responsive)
    }

    
    return (  
        <>
        <nav className='poppins-font'>
            <div>
                <div>
                    <Link to='/'>
                        <img src='../svgs/Logo.svg' alt='logo'/>
                        <span>VidMeet</span>
                    </Link>
                   
                </div>
                <div>
                    <ul>
                        <li><Link>Blog</Link></li>
                        <li><Link>FAQ</Link></li>
                        <li><Link>About us</Link></li>

                    </ul>
                </div>
                <div>
                   <Link to='/login'>Login</Link>
                   <Link to='/signup'>Sign Up</Link>
                </div>
                <div>
                    <img src='../svgs/burger.svg' alt='i'/>
                </div>
            </div>
        </nav>
      
        </>
    );
}
 
export default Header;