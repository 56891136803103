import '../../styles/Global.css'


 const Label = ({children}) =>{

    return(
        <span className='login-label'>{children}</span>
    )
}

export default Label