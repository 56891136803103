import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const useFetchProfile = (id) => {

    //the fetch profile hhok here is custom so we can use it for current user or receiver we just need an id as parameter :)
    const [profileLoading, setProfileLoading] = useState(true);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        if (!id) return;

        const ref = doc(db, 'users', id);
        //we want real time data as it is good when we update them and want instant change
        const unsubscribe = onSnapshot(ref, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userProfile = { id: docSnapshot.id, ...docSnapshot.data() };
                setProfile(userProfile);
            } else {
                setProfile(null); 
            }
            setProfileLoading(false);
        }, (error) => {
            // console.error("Error fetching real-time profile data:", error);
            setProfile(null);
            setProfileLoading(false);
        });

        return () => unsubscribe();

    }, [id,profileLoading]);

    return { profile, profileLoading };
};

export default useFetchProfile;
