import '../styles/General.css'
import { Content } from '../data/content'
import { Countries } from '../data/Countries';
import ReactSlider from "react-slider";
import { useState } from 'react';
import { useEffect } from 'react';
import { useFilter } from '../hooks/use-filter';
import '../styles/Global.css'


const Filter = ({filter , setFilter , profile , setFeed ,setFilterActive}) =>{
    const { handleFilter , filterLoading } = useFilter()
    const [ageRange, setAgeRange] = useState([18, 99])
    const [region , setRegion] = useState()
    const [filterValues , setFilterValues] = useState({content : '' , gender : '' , minAge : 18 , maxAge : 99 , region : ''})

    const handleAgeChange = (newRange) => {
        setAgeRange(newRange);
        // console.log(ageRange)
        setFilterValues(prev =>({
            ...prev,
            minAge : newRange[0],
            maxAge : newRange[1]

        }))
      };

      const FilterChange = (e) =>{
        const { name, value } = e.target;

        setFilterValues(prev =>({
            ...prev,
            [name] : value
        }))
      }
    //   console.log(filterValues)

      const FilterHandle = () =>{      
        handleFilter(filterValues,profile,setFeed,setFilter,setFilterActive)
      }

      const Reset = () =>{
        setFilterValues({content : '' , gender : '' , minAge : 18 , maxAge : 99 , region : ''})
        setAgeRange([18,99])
      }


    useEffect(()=>{
        const country = Countries.find(country => country.name === profile?.country)
        setRegion(country.regions)
    },[profile?.country])

    return(
        <div  className={`comic-font ${filter ? "filter-component" : "filter-not-active"}`}>
            <div>
                <div>
                    <img src='../svgs/UserMenuCancel.svg' onClick={()=>{setFilter(false)}} alt='menu-close'/>
                </div>
                <div>
                    <span>Content-Type:</span>
                    <select name='content' onChange={(e)=>FilterChange(e)} value={filterValues.content}>
                        <option selected disabled></option>
                        {Content.map((content)=>(
                            <option value={content}>{content}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <span>Gender:</span>
                    <div>
                        <div>
                            <label>Male</label>
                            <input type='radio' name='gender' value='Male' onChange={(e)=>FilterChange(e)} />
                        </div>
                        <div>
                            <label>Female</label>
                            <input type='radio' name='gender' value='Female' onChange={(e)=>FilterChange(e)} />
                        </div>
                        <div>
                            <label>Non-Binary</label>
                            <input type='radio' name='gender' value='Non-Binary' onChange={(e)=>FilterChange(e)} />
                        </div>
                    </div>
                </div>
                <div>
                    <span>Age:</span>
                    <div>
                        <span>{ageRange[0]}</span>
                        <span>{ageRange[1]}</span>
                    </div>
                    <ReactSlider
                        className="age-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        defaultValue={[18, 99]}
                        value={ageRange}
                        onChange={handleAgeChange}
                        min={18}
                        max={99}
                        ariaLabel={["Minimum age", "Maximum age"]}
                        step={1}
                        withTracks={true}
                     />
                </div>
                <div>
                    <span>Region:</span>
                    <select name='region' onChange={(e)=>FilterChange(e)} value={filterValues.region}>
                        <option selected disabled></option>
                        {region?.map((region)=>(
                            <option value={region}>{region}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <button onClick={Reset}>Reset</button>
                    <button onClick={()=>FilterHandle()}>{filterLoading ? '...': 'Apply'}</button>
                </div>
                
                

            </div>
        </div>
    )
}

export default Filter