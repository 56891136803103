import { CheckEmoji } from "./CheckEmoji";

export const ValidateName = (name) => {
    if (name.trim() === '') {
      return { valid: false, message: 'Please enter your name' };
    }
    if(name.includes(' ')){
        return { valid: false, message: 'Name cannot include space' };

    }
    if(CheckEmoji(name.trim())){
        return { valid: false, message: 'Name cannot include emoji' };

    }
    return { valid: true, message: ''}; 
};