import { defer } from 'react-router-dom'
import '../../styles/Global.css'


const AuthError = ({children}) =>{
    
    return (
        <span className='auth-error'>{children}</span>
    )
}

export default AuthError