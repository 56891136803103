
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "vidmeet-f6b0e.firebaseapp.com",
  projectId: "vidmeet-f6b0e",
  storageBucket: "vidmeet-f6b0e.appspot.com",
  messagingSenderId: "488234264645",
  appId: "1:488234264645:web:61fba6104491ea0b031b2c",
  measurementId: "G-FKDY6CLHBJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app); 
export const auth = getAuth(app);
export const storage = getStorage(app)
