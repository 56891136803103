import '../../styles/Global.css'


const InputSelect = ({data,disabled = false,name,onChange,value,customPlaceholder}) =>{

    return(
        <select className='input-select' name={name} onChange={onChange} value={value} disabled={disabled}>
            <option value={''} disabled selected>{customPlaceholder}</option>
            {data.map((item,index)=>(
                <option key={index} value={item}>{item}</option>
            ))}
        </select>
    )
}

export default InputSelect