import { useState } from "react";
import Name from './steps/Name';
import GenderAge from './steps/Gender-Age';
import '../../styles/SignUp.css';
import Location from "./steps/Location";
import Content from "./steps/Content";
import EthnicityAbout from "./steps/Ethnicity-About";
import EmailPassword from "./steps/Email-Password";
import Image from "./steps/Image";
import { LoadingCircle } from "../../components/LoadingCircle";
import { auth } from "../../Firebase";
import { createUserWithEmailAndPassword , sendEmailVerification,signInWithEmailAndPassword , deleteUser } from 'firebase/auth';
import EmailVerification from "./EmailVerification";
import { ValidateName } from "../../functions/ValidateName";
import { ValidateAge } from "../../functions/ValidateAge";
import { ValidateDescription } from "../../functions/ValidateDescription";
import LeftBg from "../../components/auth/LeftBg";
import AuthButton from "../../components/auth/AuthButton";
import '../../styles/Global.css'

const SignUp = () => {
  const [error, setError] = useState('');
  const [steps, setSteps] = useState(0);
  const [userData, setUserData] = useState({
    name: '',   
    gender: '',
    dateOfBirth: '',
    ethnicity: '',
    description: '',
    country: '',
    region: '',
    contentPreference1: '',
    contentPreference2: '',
    userImage : null,
    email: '',
    password: '',
    termsAndConditionAccepted : false,
  });
  const [firstSignupLoading , setFirstSignupLoading] = useState(false);
  const [userCreated , setUserCreated] = useState(null)


  const HandleChangeForm = (e) => {
    const { name, type, value, checked, files } = e.target;
  
    if (name === 'userImage' && files) {
      setUserData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else if (type === 'checkbox') {
      setUserData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  

  const SubmitEmail = async () => {
    if(!userData.termsAndConditionAccepted){
      setError('You must agree to the Terms and Conditions!')
      return
    }
    try {
        setFirstSignupLoading(true);

        const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);

        await sendEmailVerification(userCredential.user);

        setUserCreated(userCredential.user);
        setSteps((prev) => prev + 1);
    } catch (error) {
        // console.error("Error during signup:", error);

        if (error.code === 'auth/email-already-in-use') {
            // Check if email is unverified
            const userQuery = await signInWithEmailAndPassword(auth, userData.email, userData.password).catch(() => null);
            if (userQuery && !userQuery.user.emailVerified) {
                await deleteUser(userQuery.user);
                setError('Last signup wasn’t completed. Please sign up again.');
            } else {
                setError('Email already in use.');
            }
        } else if (error.code === 'auth/invalid-email') {
            setError('Please enter a valid email address');
        } else if (error.code === 'auth/missing-password') {
            setError('Please enter a password');
        } else if (error.code === 'auth/weak-password') {
            setError('Password should be at least 6 characters');
        } else {
            setError(error.message);
        }
    } finally {
        setFirstSignupLoading(false);
    }
};




  const HandleSteps = () => {
    if (steps === 0) {
      return <Name userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    } else if (steps === 2) {
      return <GenderAge userData={userData} HandleChangeForm={HandleChangeForm} error={error}  />;
    } else if (steps === 3) {
      return <EthnicityAbout userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    } else if (steps === 4) {
      return <Location userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    } else if (steps === 1) {
      return <Content userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    }
    else if (steps === 5) {
      return <Image userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    }
    else if (steps === 6) {
      return <EmailPassword userData={userData} HandleChangeForm={HandleChangeForm} error={error} />;
    }else if(steps === 7){
      return <EmailVerification userData={userData} userCreated={userCreated} setSteps={setSteps} />
    }
  };

  const validateForm = () => {
    if (steps === 0 && !ValidateName(userData.name).valid) {
      setError(ValidateName(userData.name).message);
      return false; 
    }
  
    if (steps === 2 && userData.gender.trim() === '') {
      setError('Gender is required!');
      return false;
    }
    if (steps === 2 && !ValidateAge(userData.dateOfBirth).valid) {
      setError(ValidateAge(userData.dateOfBirth).message);
      return false;
    }
    if (steps === 3 && !userData.ethnicity) {
      setError('Please select your ethnicity');
      return false;
    }
    if (steps === 3 && !ValidateDescription(userData.description).valid) {
      setError(ValidateDescription(userData.description).message)
      return false
    }
   
    if (steps === 4 && !userData.country){
      setError('Please select a country!');
      return false;
    }
    if (steps === 4 && !userData.region) {
      setError('Please select a region!');
      return false;
    }
    if (steps === 1 && (!userData.contentPreference1 && !userData.contentPreference2)) {
      setError('Select at least one content type!');
      return false;
    } 
    if(steps === 5 && !userData.userImage){
      setError('Select an image!');
      return false;
    }
    setError('');
    return true; 
  };

  

  const NextStep = (e) => {
    e.preventDefault();
    // console.log(userData);
    // console.log(steps);
    if (validateForm()) {
      if (steps === 6) {
        SubmitEmail();
      } else {
        setSteps((prev) => prev + 1);
      }
    }
  };

  console.log(userData)

  

  const PrevStep = () => {
    setError('');
    setSteps(steps - 1);
    // console.log(steps);

  };


  const signupDataHeading = [<span key="1">Hi <span>Creator</span>👋, What can we call you</span> ,
    <span key="2">What <span>type of content</span> do you collaborate on?</span>,
    <span key="3">Got it <span>{userData.name}</span>, What is your gender?</span> ,
    <span key="4">Represent your <span>ethnicity</span> and culture😊</span> ,
    <span key="5">Now <span>{userData.name}</span>, we need to know your Location📍</span> ,
    <span key="6">We need to know what <span>{userData.name}</span> looks like😃</span> ,
    <span key='7'>Almost there🥳 Choose your email and password</span>

   ];

    const signupDataSubHeading = [
    <span key="1">We  would like to hear the name you want us to call you</span>, 
    <span key="2">Select the content you are passionate about</span>, 
    <span key="3">We would like to know the gender category you fall on</span>, 
    <span key="4">Select the ethnicity you fall to</span>, 
    <span key='5'>Where do you currently base</span>,
    <span key='6'>Upload your profile picture to proceed</span>,
    <span key='7'>Input email and password you want to use </span>



  ]

  const arrayOfLength7 = Array.from({ length: 7 });


  return (
    <>

    <div className="signup-body poppins-font">
      <div>
        <LeftBg />
      </div>
      <div>
        <div>
          <div>
          {steps < 7 && 
            <>
            <div>
              <span><span>{steps + 1}</span>/7</span>
            </div>
            <div>
              {arrayOfLength7.map((_, index) => (
                <div
                  key={index}
                  className={`${index < steps + 1 ? 'track-background' : 'track'} ${
                    index === 0 ? 'track-active' : ''
                  }`.trim()}
                ></div>             
                ))}
            </div>
            </>
            }
          </div>
          <div>
            {signupDataHeading[steps]}
          </div>
          <div>
            {signupDataSubHeading[steps]}
          </div>
          <div>
            {HandleSteps()}
          </div>
          {steps < 7 && 
          <div>
            <div>
              <AuthButton loading={firstSignupLoading} text={firstSignupLoading  ? <LoadingCircle/> : 'Proceed'} Handle={NextStep}  />
            </div>
            <div>
              {steps > 0 && <span onClick={PrevStep}>Previous</span>}
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    </>
  );
};

export default SignUp;
