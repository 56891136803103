import React, { useEffect, useState } from 'react';
import AuthError from '../../../components/auth/AuthError';



const Image = ({userData , HandleChangeForm, error}) => {
    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
        HandleChangeForm(e)
    };

    useEffect(() => {
        if (userData.userImage) {
            const imageSrc = typeof userData.userImage === 'string' 
                ? userData.userImage 
                : URL.createObjectURL(userData.userImage);
            setImage(imageSrc);
        }
    }, [userData.userImage]); 



    return (
            <div className='image-signup-container'>
                <div>
                    <img src={ image || '../svgs/defaultprofile.svg'} alt='user-image' />
                </div>
                <div>
                    <input name='userImage' accept="image/*" type='file' id="fileInput" onChange={handleImageChange}  />
                    <div>
                     <label  htmlFor="fileInput" ><img src='../svgs/imageplus.svg' alt='i' /></label>
                    </div>
                </div>
                <div>
                    <AuthError>{error}</AuthError>
                </div>
            </div>
    );
}

export default Image;



