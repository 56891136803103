import '../../styles/Global.css'


const TextArea = ({name,onChange,value,placeholder}) =>{
    return(
        <textarea className='text-area-input' name={name} onChange={onChange} value={value} placeholder={placeholder}>

        </textarea>
    )
}

export default TextArea