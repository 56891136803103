import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase";
import { useUser } from "../context/User-Context";
import useFetchProfile from "./use-fetch-profile";
import { useEffect, useState } from "react";

const useFetchChat = (id) => {
    const { currentUser } = useUser();
    const [chats, setChats] = useState([]);
    const { profile, profileLoading } = useFetchProfile(id);

    //we can fetch a chat between the current user and a receiver by using the id params when navigating of each user
    //check if the id is equal to any receiverid in the userchats.chat array return the chat and use the chatId to fetch the chat in  the chat 
    //collection if it doesnt exist chat would be an empty array :)
    //make it snap shot as we need real time data :)
    useEffect(() => {
        if (profileLoading || !currentUser || !profile) {
            return;
        }

        const userChatsRef = doc(db, 'userchats', currentUser?.uid);

        const unsubscribe = onSnapshot(userChatsRef, (snapshot) => {
            if (snapshot.exists()) {
                const userChatsData = snapshot.data();
                
                const checkReceiver = userChatsData?.chats?.find((chat) => 
                    chat.receiverId === profile?.id
                );

                if (checkReceiver) {
                    const chatRef = doc(db, 'chats', checkReceiver.chatId);

                    onSnapshot(chatRef, (chatSnapshot) => {
                        if (chatSnapshot.exists()) {
                            setChats({ ...chatSnapshot.data(), id: checkReceiver.chatId });
                        }
                    });
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, [profileLoading, currentUser, profile]);

    return { chats };
};

export default useFetchChat;
