import '../../styles/Login.css'
import {Link} from 'react-router-dom'
import { useState } from 'react';
import useEmailPasswordLogin from '../../hooks/use-email-password-login';
import LeftBg from '../../components/auth/LeftBg';
import Label from '../../components/auth/Label';
import InputPassword from '../../components/auth/InputPassword';
import InputText from '../../components/auth/InputText';
import AuthError from '../../components/auth/AuthError';
import AuthButton from '../../components/auth/AuthButton';
import '../../styles/Global.css'



const Login = () =>{
    const [password , setPassword] = useState('')
    const [email ,setEmail] = useState('')

    const { loginWithEmailPassword , loginLoading , loginError , setLoginError } = useEmailPasswordLogin()


    

      const HandleLogin = (e) =>{
        console.log(email , password)
        setLoginError('')
        e.preventDefault()
        loginWithEmailPassword(email,password)
      }


            return(
            <>
            <div className='login-body poppins-font'>
                <div>
                    <LeftBg/>
                </div>
                <div>
                    <div>
                        <div>
                            <span>Welcome <span>back</span></span>
                        </div>
                        <div>
                            <span>Log in to your account and connect with other creators around the globe</span>
                        </div>
                        <div>
                            <Label>Email Address</Label>
                            <InputText placeholder='Enter Email' type='email' value={email} onChange={(e)=> setEmail(e.target.value)}></InputText>
                        </div>
                        <div>
                            <Label>Password</Label>
                            <InputPassword placeholder='Enter Password' value={password} onChange={(e)=> setPassword(e.target.value)} ></InputPassword>
                        </div>
                        <div>
                            <AuthError>{loginError}</AuthError>
                        </div>
                        <div>
                            <span><Link to='/forgottenpassword'>Forgot Password?</Link></span>
                        </div>
                        <div>
                            <AuthButton loading={loginLoading} Handle={HandleLogin} text='Login'></AuthButton>
                        </div>
                    </div>
                </div>

                {/* <div className='login-form'>
                    <div>
                        <span>Log In</span>
                    </div>
                    <form>
                        <div>
                            <input type='text' placeholder='Email' value={email} onChange={(e)=> setEmail(e.target.value)} />

                        </div>
                        <div>
                           <input type='password' value={password} ref={inputRef} placeholder='Password' onChange={(e)=> setPassword(e.target.value)} />
                           <span onClick={hashPassword}>{!hashed ? <LuEye/> : <LuEyeOff/>}</span>

                        </div>
                        <div>
                            <button disabled={loginLoading} onClick={(e)=>HandleLogin(e)}>{!loginLoading ? 'Log in' : <LoadingCircle/>}</button>
                        </div>
                        <p className='error'>{loginError}</p>
                        <div>
                            <span><Link to='/forgottenpassword'>Forgotten Password?</Link></span>
                        </div>
                        <div>
                            <span>Need an account?<Link to='/signup'>Sign up</Link></span>
                        </div>
                    </form>

                </div> */}
                  
            </div>
            </>

    )

}
export default Login