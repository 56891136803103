import {Link} from 'react-router-dom'
import { toast } from 'react-toastify'
import '../../styles/Global.css'


const Footer = () =>{
    return(
        <>

        <div className="footer poppins-font">
            <div className='inner-footer'>
                <img src='../svgs/logo-bg.svg' alt='i'/>
                <img src='../svgs/logo-bg.svg' alt='i'/>

                <div>
                    <div>
                        <div>
                            <span>Connect, Create and Grow</span>
                        </div>
                        <div>
                            <span>
                            Unite with creators worldwide, spark collaborations, and bring your ideas to life on the ultimate platform for content creators.
                            </span>
                        </div>
                    </div>
                    <div>
                        <Link to ='/signup'>Connect with creators</Link>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <div>
                                <img src='../svgs/logo-white.svg' alt='icon'/>
                                <span>VidMeet</span>
                            </div>
                            <div>
                                <span>
                                    Join VidMeet to connect with like-minded creators build
                                    confidence and start sharing content today
                                </span>
                            </div>
                            <div>
                                <p>social</p>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>General</li>
                                <li>About Us</li>
                                <li>FAQ</li>
                                <li>Connect</li>
                            </ul>
                            <ul>
                                <li>Social</li>
                                <li>Instagram</li>
                                <li>Twitter</li>
                                <li>Facebook</li>
                            </ul>
                            <ul>
                                <li>Legal</li>
                                <li>Terms of Service</li>
                                <li>Privacy Policy</li>
                                <li onClick={()=>toast.info('To request account deletion, please message us on Instagram at @vidmeet_')}>Request to Delete Account</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div>
                    <p>© 2024 <span>VidMeet™</span>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

export default Footer