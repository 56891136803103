import { useState , useRef } from 'react';
import { Link } from "react-router-dom";
import { CustomModal1 } from "../../../components/CustomModal1";
import Label from "../../../components/auth/Label";
import InputText from "../../../components/auth/InputText";
import AuthError from "../../../components/auth/AuthError";


const EmailPassword = ({userData , HandleChangeForm, error , signupError }) =>{

    const [modalOpen , setModal] = useState(false)

    
        const [hashed , setHashed] = useState(false)
    
        const inputRef = useRef(null)
    
        
    
        const hashPassword = (e) =>{
            !hashed ? inputRef.current.type = 'text' : inputRef.current.type = 'password'
            setHashed(!hashed)
          }


    return(
        <>
        {modalOpen && <CustomModal1 data='Terms and Conditions Agreement 

                Welcome to VidMeet. By accessing or using our website or services, you agree to the following terms regarding the collection and use of your data. Please read them carefully. If you do not agree to these terms, please do not use our website or services.

                1. Data Collection and Use

                We collect personal information such as your name, email address, and any other details you provide when using our website.

                This data is used to improve our services, provide customer support, and communicate with you about updates or offers.

                Your data will not be sold or shared with third parties without your consent, except as required by law.

                2. Your Consent

                By using our website, you consent to the collection, storage, and use of your data as outlined in this agreement.

                3. Modifications to Terms

                We may update these terms at any time. Continued use of our website after changes are made indicates your acceptance of the updated terms.

                4. Data Security

                We take reasonable measures to protect your data from unauthorized access or breaches. However, no system is completely secure, and we cannot guarantee the absolute security of your data.

                5. Your Rights

                You have the right to access, modify, or delete your personal information by contacting us.

                You may opt out of receiving marketing communications at any time.'  setModal = {setModal}/>}
        <div className="email-password-signup">
            <div>
                <Label>Email Address</Label>
                <InputText name="email" value={userData.email || ''} type="email" placeholder="Enter Email" onChange={HandleChangeForm}/>
            </div>
            <div>
                <Label>Password</Label>
                <div className='input-password'>
                <input className="input-text" type='password' ref={inputRef} value={userData.password || ''} name="password" placeholder='Password' onChange={HandleChangeForm} />
                    <img onClick={hashPassword} src={hashed ? '../svgs/password-hide.svg' : '../svgs/password-show.svg'} alt='i' />
                </div>
            </div>
            <div>
                <input type="checkbox"  name="termsAndConditionAccepted"   checked={userData.termsAndConditionAccepted || false}  onChange={HandleChangeForm}/>
                <span onClick={()=>setModal(true)}><Link>I agree to the Terms and Condition</Link></span>
            </div>
            <div>
                <AuthError>{error || signupError}</AuthError>
            </div>

        </div>
        </>
    )
}

export default EmailPassword