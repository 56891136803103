import '../styles/General.css'

export const EmptyData = ({title = 'No data Available For this section... 😔'}) =>{

    return(
        <div className='empty-data'>
            <p>{title}</p>
        </div>
    )
}

