import '../styles/General.css'
export const CustomModal1 = ({data , setModal}) =>{
    return(
        <div className='custom-modal-1'>
            <div>
                <img onClick={()=>setModal(false)} src="../svgs/UserMenuCancel.svg" alt='menu-cancel'/>
                <span>{data}</span>
            </div>
        </div>
    )
}