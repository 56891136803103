const getAge = (dob) => {
  const currentDate = new Date();
  const birthDate = new Date(dob);

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  const isBirthdayPassed = 
    currentDate.getMonth() > birthDate.getMonth() || 
    (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate());

  if (!isBirthdayPassed) {
    age -= 1;
  }

  return age;
};

export default getAge;
