import InputSelect from "../../../components/auth/InputSelect";
import Label from "../../../components/auth/Label";
import AuthError from "../../../components/auth/AuthError";
const Content = ({userData , HandleChangeForm, error }) => {

    
    const content = [
        'Animation',
        'Cars Vlogging',
        'Coding',
        'Dancing',
        'Food Vlogging',
        'Cooking',
        'Fashion',
        'Game Streaming',
        'Gym / Fitness',
        'Haul Videos',
        'Lifestyle Vlogging',
        'Makeup Tutorials',
        'Music',
        'Photography',
        'Podcasts',
        'Sports',
        'Tech Vlogging',
        'Travel Vlogging',
        'Video Streaming',
        'Vlogging',
    ];
    
        
    
   

    return (
        <div className="content-signup-container">
            <div>
                <Label>Preferred Content type 1</Label>
                <InputSelect data={content} customPlaceholder='Select content type' name="contentPreference1" onChange={HandleChangeForm} value={userData.contentPreference1 || null} />
            </div>
            <div>
                <Label>Preferred Content type 2</Label>
                <InputSelect data={content} customPlaceholder='Select content type' name="contentPreference2" onChange={HandleChangeForm} value={userData.contentPreference2 || null}  />
            </div>
            <div>
                <AuthError>{error}</AuthError>
            </div>
        </div>
    );
};

export default Content;
