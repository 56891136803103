import { collection, doc, setDoc, serverTimestamp, arrayUnion, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { useUser } from "../context/User-Context";

const useAddChat = () => {
    const { currentUser } = useUser();

    const addChat = async (chats, chatId, message, profile) => {

        if (message === '') {
            //if no message return early
            return;
        }

        try {
            //now we pass in the chats object as parameter if it is empty we now know it is the first time the user is texting the receiver
            if (Object.keys(chats).length === 0) {

                //create a new chat in the chat collection
                const chatRef = collection(db, 'chats');
                const newChatRef = doc(chatRef); 

                await setDoc(newChatRef, {
                    id: newChatRef.id,
                    createdAt: serverTimestamp(),
                    messages: [{
                        chatId: newChatRef.id,
                        text: message,
                        senderId: currentUser.uid,
                        createdAt: Date.now(),
                        updatedAt: Date.now(),
                    }],
                });

                //update the receiver userchats document

                await setDoc(
                    doc(db, "userchats", profile.id),
                    {
                        chats: arrayUnion({
                            isSeen : false,
                            chatId: newChatRef.id,
                            lastMessage: message,
                            receiverId: currentUser.uid,
                            updatedAt: Date.now(),
                            isShown : true,
                        })
                    },
                    { merge: true } 
                );

                 //update the sender userchats document

                await setDoc(
                    doc(db, "userchats", currentUser.uid),
                    {
                        chats: arrayUnion({
                            isSeen : true,
                            chatId: newChatRef.id,
                            lastMessage: message,
                            receiverId: profile.id,
                            updatedAt: Date.now(),
                            isShown : true,

                        })
                    },
                    { merge: true }
                );


            } else {
                //we reference the existing chat wit the chatid and update the messages array
                const chatRef = doc(db, 'chats', chatId);

                await updateDoc(chatRef, {
                    messages: arrayUnion({
                        chatId : chatRef.id,
                        text: message,
                        senderId: currentUser.uid,
                        createdAt: Date.now(),
                        isShown : true,

                    })
                });

                //fetch the existing userchat for both the currrent user and receiver user and update them
                const userIDs = [profile.id, currentUser.uid];

                userIDs.forEach(async (id) => {
                    const userChatsRef = doc(db, "userchats", id);
                    const userChatsSnapshot = await getDoc(userChatsRef);

                    if (userChatsSnapshot.exists()) {
                    const userChatsData = userChatsSnapshot.data();

                    const chatIndex = userChatsData.chats.findIndex(
                        (c) => c.chatId === chatId
                    );
                    //when  we delete from userchats we dont delete the chat in chat collection so the receiver can still see the chat
                    //that means no index found i.e it has been deleted from the userchats.chat but the chat still exists
                    if(chatIndex === -1){
                        userChatsData.chats.push(
                            {
                                chatId : chatId,
                                isSeen : id === currentUser.uid ? true : false,
                                lastMessage : message,
                                receiverId: profile.id,
                                updatedAt : Date.now(),
                                isShown : true,

                            }
                        )
                    }else{
                    userChatsData.chats[chatIndex].lastMessage = message;
                    userChatsData.chats[chatIndex].isSeen =
                        id === currentUser.uid ? true : false;
                    userChatsData.chats[chatIndex].updatedAt = Date.now();
                    }
                    userChatsData.chats[chatIndex].isShown =  true;


                    await updateDoc(userChatsRef, {
                        chats: userChatsData.chats,
                    });
                    }
                });


            }
        } catch (err) {
            // console.error("Error adding chat:", err);
        }
    };

    return { addChat };
};

export default useAddChat;
