export const Content = [
    'Animation',
    'Cars Vlogging',
    'Coding',
    'Dancing',
    'Food Vlogging',
    'Cooking',
    'Fashion',
    'Game Streaming',
    'Gym / Fitness',
    'Haul Videos',
    'Lifestyle Vlogging',
    'Makeup Tutorials',
    'Music',
    'Photography',
    'Podcasts',
    'Sports',
    'Tech Vlogging',
    'Travel Vlogging',
    'Video Streaming',
    'Vlogging',
];
